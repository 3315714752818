<template>
  <v-container class="py-5">
    <v-row align="center" justify="center" class="transparent pt-5">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-card-text>
          <h1 class="headline mb-4 black--text">{{ $t('menu.mySubscriptions') }}</h1>

          <v-tabs v-model="tab" background-color="grey lighten-4">
            <v-tab v-for="(item, index) in tabs" :key="index" @click="changeTab(index)">
              {{ item.title }}
            </v-tab>
          </v-tabs>

          <div v-if="isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <v-tabs-items v-if="!isLoading" v-model="tab">
            <v-tab-item key="subscriptions">
              <subscriptions-of-patient
                :items="subscriptions"
                :is-loading="isLoading"
                @onCancelled="cancelledSuscription"
              />
            </v-tab-item>
            <v-tab-item key="packs">
              <packs-of-patient :items="packs" :is-loading="isLoading" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import Alerts from '@/mixins/Alerts';
import PacksOfPatient from '../../components/patients/Packs.vue';
import SubscriptionsOfPatient from '../../components/patients/Subscriptions.vue';
import { subscriptionsUrl, getPacksUrl, getHeader, urlPatient, displayNameEnv } from '../../config/config';

export default {
  components: { SubscriptionsOfPatient, PacksOfPatient },
  title: displayNameEnv + ' - ' + vm.$t('menu.mySubscriptions'),
  mixins: [Alerts],
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 50,
      subscriptions: [],
      packs: [],
      tab: null,
      tabs: [
        { title: vm.$t('menu.mySubscriptions'), type: 'subscriptions', dataLoaded: false },
        { title: vm.$t('menu.packs'), type: 'packs', dataLoaded: false },
      ],
    };
  },
  mounted() {
    this.requestData('subscriptions');
    this.tabs[0].dataLoaded = true;
  },
  methods: {
    ...mapActions('layout', ['showAlert']),

    requestData(type) {
      this.isLoading = true;
      const url = type === 'subscriptions' ? subscriptionsUrl : getPacksUrl;
      this.$http
        .get(urlPatient(url, this.perPage, this.currentPage), { headers: getHeader() })
        .then(res => {
          if (type === 'subscriptions') {
            this.subscriptions = this.setData(res.body.data);
          } else {
            this.packs = res.body.data;
          }
        })
        .catch(err => {
          this.$log.error(err);
          if (err && err.response && err.response.data) {
            this.toastError(err.response.data.detail);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    changeTab(index) {
      if (!this.tabs[index].dataLoaded && this.tabs[index].type === 'packs') {
        this.requestData('packs');
        this.tabs[index].dataLoaded = true;
      }
    },

    setData(data) {
      const res = [];
      data.forEach(item => {
        item.services.forEach(service => {
          service.serviceName = service.type === 1 ? this.$t('common.messaging') : service.name;
          service.serviceIcon = service.type === 1 ? 'mdi-forum-outline' : 'mdi-video-wireless-outline';
          service.typeName = service.type === 1 ? 'message' : 'appointment';
        });
        item.isLoading = false;
        res.push(item);
      });
      return res;
    },

    cancelledSuscription() {
      this.showAlert({ color: 'success', icon: 'mdi-check', message: this.$t('subscriptions.cancelled_ok') });
      this.requestData('subscriptions');
    },
  },
};
</script>
