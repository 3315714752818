<template>
  <v-container>
    <v-card v-for="item in items" :key="item.id" outlined class="mb-5">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="item.professional.image" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.professional.name" />
            <v-list-item-subtitle
              >{{ $t('user.num_colegiado') }}: {{ item.professional.collegiateNumber }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
            <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-chip class="red--text" color="grey lighten-4">{{
              $t('packs.num_pending', { num: item.sessionsPending })
            }}</v-chip>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
    <div v-if="!isLoading && (!items || !items.length)" class="py-5 text-center grey-darken-1--text">
      {{ $t('packs.not_have_hired') }}
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'PacksOfPatient',
  props: {
    items: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
};
</script>
