<template>
  <v-container>
    <v-card v-for="(item, indexSubscription) in items" :key="item.id" outlined class="mb-5">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="item.professional.image" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.professional.name" />
            <v-list-item-subtitle
              >{{ $t('user.num_colegiado') }}: {{ item.professional.collegiateNumber }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.status === 'cancelled' ? $t('subscriptions.expire') : $t('subscriptions.renewal') }}:
              <span v-tooltip="item.renewalAt">{{ item.renewalAt | moment('DD/MM/YYYY') }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon class="btn-services">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-tooltip="$t('subscriptions.request_new_service')"
                  depressed
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2">mdi-plus</v-icon>{{ $t('menu.servicios') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(service, index) in item.services"
                  :key="index"
                  :to="'/hiring/' + item.professional.id + '/' + service.typeName + '/' + service.id"
                  :disabled="service.uses.remaining === 0"
                >
                  <v-list-item-title>
                    <v-icon class="mr-3">{{ service.serviceIcon }}</v-icon
                    >{{ service.serviceName }} ({{ service.uses.total === -1 ? '∞' : service.uses.remaining }})
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu v-if="item.status === 'active'" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-progress-circular
                  v-show="item.isLoading"
                  indeterminate
                  :size="32"
                  :width="2"
                  color="primary"
                  style="margin-left: 4px"
                />
                <v-btn v-show="!item.isLoading" icon color="grey" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="showDialogCancelSubscription(item, indexSubscription)">
                  <v-list-item-title>{{ $t('suscripciones.cancelar_suscripcion') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
    <div v-if="!isLoading && (!items || !items.length)" class="py-5 text-center grey-darken-1--text">
      {{ $t('subscriptions.not_have_hired') }}
    </div>
    <dialog-confirm :dialog="dialog" @btnDialog="btnDialog" />
  </v-container>
</template>

<script>
import DialogConfirm from '../common/DialogConfirm.vue';
import { getHeader, subscriptionsUrl, urlPatient } from '../../config/config';

export default {
  name: 'SubscriptionsOfPatient',
  components: { DialogConfirm },
  props: {
    items: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: {
        show: false,
        title: vm.$t('suscripciones.cancelar_suscripcion'),
        text: '',
        maxwidth: 420,
        idSelected: 0,
        index: 0,
        buttons: [
          { text: vm.$t('common.no'), color: 'grey', click: 'cancel' },
          { text: vm.$t('common.confirm'), color: 'red darken-1', click: 'ok' },
        ],
      },
    };
  },
  methods: {
    showDialogCancelSubscription(subscription, index) {
      const d = subscription.renewalAt.split('T');
      this.dialog.idSelected = subscription.id;
      this.dialog.index = index;
      this.dialog.text = this.$t('subscriptions.confirm_cancel_explain', { date: d[0] });
      this.dialog.show = true;
    },

    btnDialog(action) {
      this.dialog.show = false;
      if (action === 'ok') {
        this.cancelSubscription(this.dialog.idSelected, this.dialog.index);
      }
    },

    cancelSubscription(id, index) {
      if (!this.items[index].isLoading) {
        this.items[index].isLoading = true;
        this.$http
          .get(urlPatient(subscriptionsUrl, 0, 0) + '/' + id + '/cancel', { headers: getHeader() })
          .then(res => {
            if (res.body.success) {
              this.$emit('onCancelled');
            }
          })
          .catch(err => {
            this.$log.error(err);
            if (err && err.response && err.response.data) {
              this.toastError(err.response.data.detail);
            }
          })
          .finally(() => {
            this.items[index].isLoading = false;
          });
      }
    },
  },
};
</script>
<style>
.btn-cancel {
  position: absolute;
  top: -15px;
  right: -7px;
}
.btn-services {
  margin-right: 0px;
  padding: 12px 0;
}
</style>
